import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const CryptoButterfly = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Crypto Butterfly" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Crypto Butterfly</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Apr. 15, 2022) </p>
        </div>
        <div className="articleBody">
          <p>
            <i>Disclaimer</i>: The following is not financial advice. I mean, it
            is, but I can’t be held legally accountable if you act on it.
            Remember, I’m a trillionaire, and you, sadly, will never be. Damn,
            that’s so sad. Wow aren’t you sad about that bro?{" "}
          </p>
          <p>
            Some time ago I was made aware of an investing strategy that some of
            you savvy working class people use to manage your pitiful little $2M
            (?) annual incomes. It’s an ingenious approach to managing a
            portfolio referred to as the{" "}
            <a
              href="https://portfoliocharts.com/portfolio/golden-butterfly/"
              target="__blank"
              rel="noopener noreferrer"
            >
              Golden Butterfly
            </a>
            .
          </p>
          <p>
            For those of you too lazy to follow the link, the strategy involves
            splitting your entire portfolio into five assets: 20% in
            total-market stocks, 20% in small-cap value stocks, 20% in long term
            bonds, 20% in short term bonds, and 20% in the butterfly’s head,
            gold.
          </p>
          <p>
            The philosophy of this approach is oriented toward risk tolerance
            (I’m told y’all mere mortals need to do something called “saving for
            retirement”), and as such, completely incompatible with my needs.
            But I thought it might be fun to spin up a fund of my own and
            experiment with the strategy for my own use case (fork the repo, so
            to speak).
          </p>
          <p>
            I decided to invest $500M and track it over the course of a year.
            Without ado, here’s the Crypto Butterfly: 20% in BTC, 20% in $TSLA,
            20% in [REDACTED], 20% in an NFT of Picasso’s Guernica, and 20% in
            the butterfly’s head, SHYC (that’s ShoyaCoin mf!!! lmao).
          </p>
          <p>The returns on this investment after 1 year are as follows:</p>
          <p>
            It’s a cool night overlooking the bright but sparse Nairobi skyline.
            An ultrasonic noise causes every dog in the city to start barking
            and howling. Shortly after that, the lights in all the skyscrapers
            go dark — it’s a total blackout.
          </p>
          <p>
            I’m on the outskirts of Nairobi National Park, flanked by two aides
            in grey-green-purple jumpsuits. We’re all wearing hard hats, and I’m
            shirtless. We’re bathed in an ominous glow from above, despite the
            moonless night. One of the aides shows me something on a Space Gray
            12.9” iPad Pro.
          </p>
          <p>
            AIDE: Sir, we predict that SHYC will cross the $00.00007 threshold
            in approximately 3 minutes.
          </p>
          <p>I nod, knowingly.</p>
          <p>ME: Be ready.</p>
          <p>We all face the treeline.</p>
          <p>
            3 minutes later, we hear a loud cracking sound and the ominous glow
            gets much brighter. Our eyes widen. We’re looking up at a purplish
            and semi-transluscent chrysalis the size of a house that’s hanging
            from a gigantic tree. As the chrysalis shatters, the ultrasonic
            noise blares out from within. It seems I’m the only one of us who
            hears it — I cover my ears and grimace. The light gets brighter, the
            noise gets louder, and I faint, falling into the grass. The last
            thing I hear before blacking out completely is the sound of large
            wings flapping.
          </p>
          <p>
            Anyway{" "}
            <a
              href="https://www.youtube.com/watch?v=pgtmlVqCKxc"
              target=""
              rel="noopener noreferrer"
            >
              nature
            </a>{" "}
            is cool as shit lmao but yeah I earned a 15% return in the end,
            mostly driven by the 20% that was invested in [REDACTED], now{" "}
            <a
              href="https://youtu.be/JUYMC9revJE?t=9"
              target="__blank"
              rel="noopener noreferrer"
            >
              get ya money up not ya funny up grrrrrrowr
            </a>
            !!!
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default CryptoButterfly
